<template>
  <div class="page-content-inner pt-5 pr-lg-0">
    <div class="section-header pb-0 mt-2 mb-4 row">
      <div class="col-md-8   section-header-left">
        <h3 class="uk-text-truncate">
          <i class="uil-books"></i> {{ $t("education.catalog_education") }}
        </h3>
      </div>
      <div class="section-header-left">
        <!-- <form action="#"> -->
        <input v-model="searchQuery" :placeholder="$t('general.search')" style="width: 97%;" type="text"
               @input="isTyping = true">
        <!-- </form> -->
      </div>
    </div>

    <!-- <div class="section-header pb-0 mb-4">
      <div class="section-header-left">
        <form action="" @submit.prevent="applyFilter">
          <input v-model="searchQuery" :placeholder="$t('home.Education_shearing')" type="text"
            @input="isTyping = true" />
        </form>
      </div>
      <div class="section-header-right">
        <select v-model="order_by" class="selectpicker ml-3 mb-0" @change="applyFilter">
          <option value="name">{{ $t("filter.Filter_by_name") }}</option>
          <option value="training_type_id">
            {{ $t("filter.Filter_by_category") }}
          </option>
          <option value="created_at">{{ $t("filter.Filter_by_date") }}</option>
        </select>
      </div>
    </div> -->

    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-1-3@m">
        <nav class="responsive-tab style-3 setting-menu card"
             uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-bottom">
          <ul class="side-list" uk-accordion="multiple:true">
            <li class="">
              <a class="uk-accordion-title" href="#"><i class="uil-book-alt"></i>
                {{ $t("general.platform_trainings") }}
              </a>
              <div class="uk-accordion-content">
                <ul class="ks-cboxtags">
                  <li v-for="(item, index) in educationTypes" :key="index">
                    <input id="checkboxOne" v-model="options" :disabled="catalogs_loading" :value="item.id"
                           type="checkbox"
                           @change="applyFilter">
                    <label for="checkboxOne">
                      {{ item.name }}
                    </label>
                  </li>
                </ul>
              </div>
            </li>
            <li class="">
              <a class="uk-accordion-title" href="#"><i class="uil-book-alt"></i>
                {{ $t("general.institution_trainings") }}
                <!-- Kurum Eğitimleri -->
              </a>
              <div class="uk-accordion-content">
                <ul class="ks-cboxtags">
                  <li v-for="(item, index) in institutionTraining" :key="index">
                    <input id="checkboxOne" v-model="options" :disabled="catalogs_loading" :value="item.id"
                           type="checkbox"
                           @change="applyFilter">
                    <label for="checkboxOne">
                      {{ item.name }}
                    </label>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="uk-accordion-title" href="#"><i class="uil-notes"></i>
                {{ $t('general.training_types') }}
              </a>
              <div class="uk-accordion-content">
                <ul class="ks-cboxtags">
                  <li>
                    <input id="checkboxTypeOne"
                           v-model="isAllTrainingKindsSelected"
                           :disabled="catalogs_loading"
                           type="checkbox"
                           @change="handleAllTrainingKindCheckboxChange"/>
                    <label for="checkboxTypeOne">{{ $t('general.all') }}</label>
                  </li>
                  <li v-for="(item, index) in formattedTrainingKind" :key="index">
                    <input :id="`checkboxTwo_${index}`"
                           v-model="kinds"
                           :disabled="catalogs_loading"
                           :value="item.id"
                           type="checkbox"
                           @change="handleIndividualTrainingKindsChange"/>
                    <label :for="`checkboxTwo_${index}`">{{ item.name }}</label>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div class="uk-width-2-3@m">
        <!-- LOADING BOX -->
        <default-loading v-if="catalogs_loading"></default-loading>
        <template v-else>
          <div class="card rounded">
            <nav class="responsive-tab style-2" style="margin-left: 2rem">
              <ul>
                <li :class="{
                  'uk-active': tabIndex === 0,
                }">
                  <a :disabled=catalogs_loading @click="setFilterItem('can_register_trainings', 0)">
                    {{ $t("general.i_can_register") }}
                    <div class="star-rating">
                      <span class="avg">
                        <data v-if="tabIndex === 0">{{ totalFilteredTraining }} / </data>

                        {{ trainingCounts["countOfRegistrable"] ?? 0 }}
                      </span>
                    </div>
                  </a>

                </li>
                <li :class="{
                  'uk-active': tabIndex === 1,
                }">
                  <a :disabled=catalogs_loading @click="setFilterItem('show_registered_trainings', 1)">
                    {{ $t("general.i_registered") }}
                    <div class="star-rating">
                      <span class="avg">
                        <data v-if="tabIndex === 1">{{ totalFilteredTraining }} / </data>
                        {{ trainingCounts["countOfRegistered"] ?? 0 }}
                      </span>
                    </div>
                  </a>
                </li>
                <li :class="{
                  'uk-active': tabIndex === 2,
                }">
                  <a :disabled=catalogs_loading @click="setFilterItem('requests_trainings', 2)">
                    {{ $t("general.requests") }}
                    <div class="star-rating">
                      <span class="avg">
                        <data v-if="tabIndex === 2">{{ totalFilteredTraining }} / </data>

                        {{ trainingCounts["countOfRequests"] ?? 0 }}
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </nav>
            <template v-for="(subItem, index) in items.data" v-if="items && items.data && items.data.length">
              <hr class="m-0"/>
              <div class="uk-grid-small p-4" uk-grid>
                <div class="uk-width-1-4@m">
                  <img :src="subItem.cover_image_link" alt="" class="rounded" style="cursor: pointer"/>
                </div>
                <div class="uk-width-expand">
                  <div href="javascript:void(0)">
                    <h5 class="mb-2">
                      <span style="cursor: pointer">
                        {{ subItem.name }}
                      </span>
                      <button v-if="subItem.has_been_assigned" class="btn float-right btn-sm btn-info" type="button"
                              @click="handleOnClickCard(subItem.id)">
                        <i class="uil-play-circle"></i>
                        {{ getBottomTitle(subItem.completion_percentage) }}
                      </button>
                      <template v-else>
                        <template v-if="filters.can_register_trainings">


                            <button v-if="subItem.self_registrable_trainings?.length > 0"
                                    class="btn float-right btn-sm btn-success" style="margin-right: 5px"
                                    type="button" @click="requestForSelfTraining(subItem.id)">
                              <i class="uil-check-circle"  style="color: white !important;"/>
                              {{ $t("general.sign_up") }}
                            </button>

                            <button v-if="subItem.self_registrable_trainings?.length > 0"
                                    class="btn float-right btn-sm btn-success" style="margin-right: 5px"
                                    type="button" @click="gotoDemoPage('goDemoPage', subItem.id)">
                               <span class="mr-1 d-flex justify-content-center align-items-baseline"  style="color: white !important;" >
                                      <i class="uil-play-circle mr-2" style="color: white !important;" />
                                 DEMO
                               </span>
                            </button>


                        </template>
                        <template v-if="filters.requests_trainings">
                          <button v-if="!subItem.user_request &&
                            !subItem.self_registrable_trainings?.length > 0
                            " class="btn float-right btn-sm btn-primary" style="margin-right: 5px"
                                  type="button" @click="requestForTraining(subItem.id)">
                            <i class="uil-bell"></i> {{ $t("general.request") }}
                          </button>
                          <button v-if="subItem.user_request &&
                              subItem.user_request.training_status_id == 1
                              " class="btn float-right btn-sm btn-secondary disabled"
                                  style="margin-right: 5px" type="button">
                            <i class="fa fa-check"></i> {{ $t("general.requested") }}
                          </button>
                          <button v-if="subItem.user_request &&
                            subItem.user_request.training_status_id == 0
                            " class="btn float-right btn-sm btn-danger" style="margin-right: 5px"
                                  type="button">
                            {{ $t("general.reject_request") }}
                          </button>
                        </template>
                      </template>
                    </h5>
                    <p class="mb-0 uk-text-small mt-3">
                      <span class="mr-3 bg-light p-2 mt-1">
                        {{
                          $t("education.section_count", {
                            number: subItem.training_content_count,
                          }) +
                          " / " +
                          $t("education.content_count", {
                            number: subItem.training_content_count,
                          })
                        }}</span>
                    </p>
                    <p class="uk-text-small mb-2 mt-4">
                      {{ subItem.description }}
                    </p>
                    <p class="uk-text-small mb-1 mt-1">{{ subItem.training_type?.name }}</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <Pagination v-if="items && items.data && items.last_page !== 1" :page.sync="pageProxy" :paginate="items">
          </Pagination>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import EducationCard from "@/view/components/index/EducationCard";
import Pagination from "@/view/components/dataTable/Pagination";
import moment from "moment";
import store from "@/core/services";
import {
  GET_ITEMS,
  ITEMS,
  CREATE_ITEM,
  LOADING,
  UPDATE_ITEM_BY_ID,
  GET_COUNTS,
} from "@/core/services/store/REST.module";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import {mapGetters} from "vuex";

import training_kind_module, {
  BASE_URL as TRAINING_KIND_URL,
  GET_ITEMS as GET_TRAINING_KINDS,
  MODULE_NAME as MODULE_TRAINING_KIND,
  ERROR,
  ITEMS as TRAINING_KINDS,
} from "@/core/services/store/training_kind.module";
import SecureLS from "secure-ls";
import {nextTick} from "vue";

const ls = new SecureLS({encodingType: "aes", isCompression: true});
const _TRAINING_KIND_MODULE = MODULE_TRAINING_KIND;

export default {
  name: "EducationCatalog",
  components: {
    EducationCard,
    Pagination,
    DefaultLoading,
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_TRAINING_KIND_MODULE, training_kind_module);
  },
  data() {
    return {
      totalFilteredTraining: null,
      search: null,
      searchQuery: "",
      isTyping: false,
      searchResult: [],
      options: [0],
      kinds: [],
      order_by: "name",
      sort: "desc",
      restUrl: "api/training",
      trainingRequestUrl: "api/training/request/",
      educationTypes: [],
      selected_type: {
        id: null,
        name: this.$t("all_categories"),
      },
      filters: {
        page: 1,
        search: null,
        training_type_id: 0,
        // training_kind_id: 0,
        can_show_self_registrable_trainings: true,
        show_registered_trainings: false,
        can_register_trainings: true,
        requests_trainings: true
      },
      tabIndex: 0,
      selected_type_name: null,
      trainingCategoryUrl: "api/training-type",
      page: 1,
      trainingCounts: [],
      institutionTraining: [],
      catalogs_loading: true,
      isTabClicked: false,
      isAllTrainingKindsSelected: false,

    };
  },
  computed: {
    ...mapGetters({
      items: ITEMS,
      // catalogs_loading: LOADING,
    }),
    training_kinds: {
      get() {
        return store.getters[_TRAINING_KIND_MODULE + "/" + TRAINING_KINDS];
      },
      set(value) {
      },
    },
    formattedTrainingKind() {
      let results = [];
      if (this.training_kinds === null || !("data" in this.training_kinds))
        return [];
      this.training_kinds.data.forEach((item) => {
        results.push({
          id: item.id,
          value: item.id,
          name: item.name,
        });
      });
      return results;
    },

    pageProxy: {
      set(value) {
        this.page = value;
        this.applyFilter();
      },
      get() {
        return this.page;
      },
    },
  },
  methods: {
    handleAllTrainingKindCheckboxChange() {
      if (this.isAllTrainingKindsSelected) {
        this.kinds = this.formattedTrainingKind.map(item => item.id);
      } else {
        this.kinds = [];
      }
      this.applyFilter();
    },
    handleIndividualTrainingKindsChange() {
      // Update "All" checkbox state based on individual selections
      this.isAllTrainingKindsSelected = this.formattedTrainingKind.length > 0 &&
          this.kinds.length === this.formattedTrainingKind.length;

      this.applyFilter();
    },
    getTrainingCounts() {
      let self = this;
      this.$store
          .dispatch(GET_COUNTS, {
            url: this.restUrl + "-counts",
            acceptPromise: false,
            showLoading: true,
          })
          .then((result) => {
            self.trainingCounts = result.data;
          });
    },
    handleOnClickCard(payload) {
      this.$router.push({
        name: "user.eduction-detail",
        params: {id: payload},
      });
    },

    gotoDemoPage(event, payload) {
      if (event === 'goDemoPage') {
        this.$router.push({name: 'user.eduction-detail', params: {id: payload}})
      }
    },
    fixDate(value) {
      return moment(value).locale(this.secureLocalStorage("language")).format('DD MMMM YYYY');
    },
    setFilterItem(key, index) {
      this.tabIndex = index;
      this.resetPartialFilters();
      this.isTabClicked = true
      this.filters[key] = "true";
      if (key === "can_register_trainings" || key === 'requests_trainings') {
        this.filters["requests_trainings"] = true;
      } else {
        this.filters["requests_trainings"] = false;
      }

      this.applyFilter();

    },
    resetPartialFilters() {
      this.filters.show_registered_trainings
          ? (this.filters.show_registered_trainings = false)
          : null;
      this.filters.can_register_trainings
          ? (this.filters.can_register_trainings = false)
          : null;
      this.filters.requests_trainings
          ? (this.filters.requests_trainings = false)
          : null;
    },
    requestForTraining(id) {
      this.$store
          .dispatch(CREATE_ITEM, {
            url: this.trainingRequestUrl + id,
            acceptPromise: true,
          })
          .then((result) => {
            if (result) {
              this.applyFilter();
              // this.item.user_request = result.data;
            }
          });
    },
    requestForSelfTraining(item_id) {
      this.$store
          .dispatch(UPDATE_ITEM_BY_ID, {
            url: "api/trainings/" + item_id + "/self-registrable",
            // noLoading: true,
          })
          .then((result) => {
            if (result) {
              this.getTrainingCounts();
              this.applyFilter();
            }
          });
    },
    applyFilter(event = null) {
      this.catalogs_loading = true;

      this.$set(this.filters, "page", this.page);
      // this.$set(this.filters, 'training_type_id', this.selected_type.id)
      if (this.options.length === 0 || (this.options[0] === 0 && this.options.length === 1)) {
        delete this.filters['training_type_id[]'];
        this.filters.training_type_id = 0;
      } else if (this.options.length > 0) {
        delete this.filters.training_type_id;
        this.options.every((element, index) => {
          if (element === 0) {
            this.options.splice(index, 1)
            return false
          } else {
            return true
          }
        });
        this.$set(this.filters, "training_type_id[]", this.options);
      }

      if (this.kinds.length === 0) {

        delete this.filters['training_kind_id[]'];
        this.filters.training_kind_id = 0;
      } else {
        delete this.filters.training_kind_id;
        this.$set(this.filters, "training_kind_id[]", this.kinds);
      }

      this.$set(this.filters, "search", this.search);
      this.$set(this.filters, "order_by", this.order_by);
      this.$set(this.filters, "by_user", true);
      this.$set(this.filters, "search", this.searchQuery);


      if (event || this.isTabClicked) {
        //don't trigger at onMounted Fn to prevent of looping
        this.updateUrlQuery()
      }

      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        showLoading: true,
        filters: this.filters,
      }).then((response) => {
        if (response) {
          this.catalogs_loading = false;
          this.totalFilteredTraining = response.data?.total
        }
      });
    },
    updateUrlQuery() {
      const query = {...this.$route.query};

      const educationQueryIds = [...this.options]

      if (educationQueryIds.length > 0) {
        query.education = educationQueryIds.join(',');
      } else {
        delete query.education;
      }

      // Handle trainingKinds filter
      if (this.kinds.length > 0 && !(this.kinds.length === 1 && this.kinds[0] === 0)) {

        // Filter out the "0" (all) option if other options are selected
        const filteredKinds = this.kinds
        if (filteredKinds.length > 0) {
          query.trainingKinds = filteredKinds.join(',');
        } else {
          delete query.trainingKinds;
        }
      } else {
        delete query.trainingKinds;
      }

      // Keep existing tab parameter if present
      if (this.tabIndex !== undefined) {
        query.tab = this.getTabKeyByIndex(this.tabIndex);
        query.tabId = this.tabIndex;
      }
      // Update the URL without reloading the page
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({query}).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    // Add a method to parse URL query parameters and set filters
    loadFiltersFromUrl() {
      const query = this.$route.query;

      this.options = [0];
      this.kinds = [];

      // Parse education types from URL
      if (query.education) {
        const educationIds = query.education.split(',').map(id => parseInt(id));
        this.options = this.options.filter(opt => opt === 0).concat(educationIds);
      }

      if (query.trainingKinds) {
        const trainingIds = query.trainingKinds.split(',').map(id => parseInt(id));
        this.kinds = this.kinds.filter(k => k === 0).concat(trainingIds);
      }

      // tab query checks on the left side
      if (query.tab && query.tabId) {
        this.setFilterItem(query.tab, parseInt(query.tabId));
      }
      // Apply filters without updating URL again
      this.applyFilterWithoutUrlUpdate();
    },
    // Version of applyFilter that doesn't update URL (to avoid loops)
    applyFilterWithoutUrlUpdate() {
      this.catalogs_loading = true;

      this.$set(this.filters, "page", this.page);

      if (this.options.length === 0 || (this.options[0] === 0 && this.options.length === 1)) {
        delete this.filters['training_type_id[]'];
        this.filters.training_type_id = 0;
      } else if (this.options.length > 0) {
        delete this.filters.training_type_id;
        // Remove the "0" all option if specific options are selected
        if (this.options.length > 1 && this.options.includes(0)) {
          this.options = this.options.filter(opt => opt !== 0);
        }
        this.$set(this.filters, "training_type_id[]", this.options);
      }

      if (this.kinds.length === 0 || (this.kinds[0] === 0 && this.kinds.length === 1)) {
        delete this.filters['training_kind_id[]'];
        this.filters.training_kind_id = 0;
      } else if (this.kinds.length > 0) {
        delete this.filters.training_kind_id;
        this.$set(this.filters, "training_kind_id[]", this.kinds);
      }

      this.$set(this.filters, "search", this.search);
      this.$set(this.filters, "order_by", this.order_by);
      this.$set(this.filters, "by_user", true);
      this.$set(this.filters, "search", this.searchQuery);

      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        showLoading: true,
        filters: this.filters,
      }).then((response) => {
        if (response) {
          this.catalogs_loading = false;
          this.totalFilteredTraining = response.data?.total
        }

      });
    },
    getTabKeyByIndex(index) {
      switch (index) {
        case 0:
          return 'can_register_trainings';
        case 1:
          return 'show_registered_trainings';
        case 2:
          return 'requests_trainings';
        default:
          return 'can_register_trainings';
      }
    },
    getBottomTitle(percentage) {
      switch (percentage) {
        case 0:
          return this.$t("general.start");
        case 100:
          return this.$t("general.completed");
        default:
          return this.$t("general.continue");
      }
    },
    checkActiveAccordions() {
      // Wait for DOM to be ready
      this.$nextTick(() => {
        // Find all accordion sections
        const accordionSections = document.querySelectorAll('.side-list > li');
        accordionSections.forEach((section) => {
          section.classList.add('uk-open');
        });
      });
    }
  },
  watch: {
    searchQuery: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.applyFilter()
      }
    },
  },
  mounted() {
    if (!this.$isCatalogTrainingVisible) {
      this.$router.push({name: "user.index"});
    }
    $(".selectpicker").selectpicker();
    this.selected_type_name = this.$t("all_categories");

    this.$store
        .dispatch(GET_ITEMS, {
          url: this.trainingCategoryUrl,
          acceptPromise: true,
        })
        .then((response) => {
          if (response.status) {
            response.data.forEach(element => {
              if (element.company_id === 1)
                this.educationTypes.push(element)
              else
                this.institutionTraining.push(element)
            });
          }

          store.dispatch(_TRAINING_KIND_MODULE + "/" + GET_TRAINING_KINDS, {
            url: TRAINING_KIND_URL,
          });
          this.getTrainingCounts();


        });

    // Load filters from URL after data is loaded
    nextTick(() => {
      if (Object.keys(this.$route.query).length > 0) {
        this.loadFiltersFromUrl();
        //if has queries kopen accordion
        this.checkActiveAccordions()
      } else {
        this.applyFilter();
      }
    });


  }
};
</script>

<style scoped>
ul.ks-cboxtags li input[type="checkbox"] {
  height: 100%;
  background-color: var(--backgroundColor) !important;

}
</style>
